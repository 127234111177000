import React from "react"
import theme from "theme"
import { Theme, Text, Box, Image, LinkBox, Section } from "@quarkly/widgets"
import { Helmet } from "react-helmet"
import { GlobalQuarklyPageStyles } from "global-page-styles"
import { RawHtml } from "@quarkly/components"
import * as Components from "components"
export default () => {
  return (
    <Theme theme={theme}>
      <GlobalQuarklyPageStyles pageUrl={"about"} />
      <Helmet>
        <title>BIGGYM Fitness'a Hoş Geldiniz</title>
        <meta
          name={"description"}
          content={"Daha İyi Bir Size Yolculuğunuz Burada Başlıyor!"}
        />
        <meta property={"og:title"} content={"BIGGYM Fitness'a Hoş Geldiniz"} />
        <meta
          property={"og:description"}
          content={"Daha İyi Bir Size Yolculuğunuz Burada Başlıyor!"}
        />
        <meta
          property={"og:image"}
          content={"https://uvixens.com/img/1.jpg"}
        />
        <link
          rel={"shortcut icon"}
          href={"https://uvixens.com/img/icon.png"}
          type={"image/x-icon"}
        />
        <link
          rel={"apple-touch-icon"}
          href={"https://uvixens.com/img/icon.png"}
        />
        <link
          rel={"apple-touch-icon"}
          sizes={"76x76"}
          href={"https://uvixens.com/img/icon.png"}
        />
        <link
          rel={"apple-touch-icon"}
          sizes={"152x152"}
          href={"https://uvixens.com/img/icon.png"}
        />
        <link
          rel={"apple-touch-startup-image"}
          href={"https://uvixens.com/img/icon.png"}
        />
        <meta
          name={"msapplication-TileImage"}
          content={"https://uvixens.com/img/icon.png"}
        />
      </Helmet>
      <Components.Header />
      <Section
        padding="80px 0 80px 0"
        lg-padding="60px 0 60px 0"
        md-padding="40px 0 40px 0"
        sm-padding="30px 0 30px 0"
      >
        <Box
          margin="0px 0px 32px 0px"
          display="flex"
          flex-direction="column"
          align-items="flex-start"
        >
          <Text
            font="--headline1"
            color="--dark"
            text-align="center"
            md-font='normal 700 30px/1.2 "Source Sans Pro", sans-serif'
            margin="16px 0px 0px 0px"
          >
            Hakkımızda
          </Text>
        </Box>
        <LinkBox
          display="flex"
          margin="0px 0px 32px 0px"
          md-flex-direction="column"
          md-align-items="center"
          md-justify-content="center"
          flex-direction="row"
          align-items="flex-start"
          flex-wrap="wrap"
          lg-margin="0px 0px 16px 0px"
        >
          <Box
            width="50%"
            padding="0px 16px 0px 0px"
            align-items="flex-start"
            display="flex"
            justify-content="flex-start"
            margin="0px 0px 0px 0px"
            md-width="100%"
            lg-width="100%"
            lg-padding="0px 0px 0px 0px"
          >
            <Image
              src="https://uvixens.com/img/2.jpg"
              border-radius="24px"
              max-width="100%"
              max-height="356px"
              width="100%"
              object-fit="none"
            />
          </Box>
          <Box
            width="50%"
            padding="0px 0px 0px 16px"
            display="flex"
            align-items="flex-start"
            justify-content="flex-start"
            md-width="100%"
            sm-align-items="flex-start"
            sm-flex-direction="column"
            sm-justify-content="flex-start"
            flex-direction="column"
            lg-width="100%"
            lg-flex-direction="row"
            lg-justify-content="space-between"
            lg-padding="16px 12px 16px 12px"
          >
            <Box
              padding="0px 50px 0px 0px"
              lg-width="70%"
              sm-width="100%"
              lg-padding="0px 0px 0px 0px"
            >
              <Text margin="20px 0px 8px 0px" font="--headline3" color="--dark">
                Bizim Hikayemiz
              </Text>
              <Text margin="0px 0px 0px 0px" font="--lead" color="--darkL2">
                BIGGYM Fitness'ta, fitness'a giden her yolculuğun anlatılmaya
                değer bir hikaye olduğuna inanıyoruz. Güç, topluluk ve dönüşüm
                ilkelerine dayanarak, hedeflerin karşılandığı ve beklentilerin
                aşıldığı bir alan inşa ettik. Hikayemiz basit bir fikirle
                başladı: fitness seviyeleri ne olursa olsun herkesin
                gelebileceği ve kendi üzerinde çalışmak için güçlenmiş
                hissedebileceği kapsayıcı bir ortam yaratmak. Bugün BIGGYM
                Fitness, adanmışlığın ve tutkunun gücünün bir kanıtı olarak
                duruyor. Topluluğumuz, her biri daha iyi sağlık ve mutluluk için
                kendi benzersiz yolunda ilerleyen ilham verici bireylerden
                oluşan bir eritme potasıdır.
              </Text>
            </Box>
          </Box>
        </LinkBox>
        <LinkBox
          display="flex"
          margin="0px 0px 32px 0px"
          md-flex-direction="column"
          md-align-items="center"
          md-justify-content="center"
          flex-direction="row"
          align-items="flex-start"
          flex-wrap="wrap"
          lg-margin="0px 0px 16px 0px"
        >
          <Box
            width="50%"
            padding="0px 0px 0px 16px"
            display="flex"
            align-items="flex-start"
            justify-content="flex-start"
            md-width="100%"
            sm-align-items="flex-start"
            sm-flex-direction="column"
            sm-justify-content="flex-start"
            flex-direction="column"
            lg-width="100%"
            lg-flex-direction="row"
            lg-justify-content="space-between"
            lg-padding="16px 12px 16px 12px"
          >
            <Box
              padding="0px 50px 0px 0px"
              lg-width="70%"
              lg-padding="0px 0px 0px 0px"
              sm-width="100%"
            >
              <Text margin="20px 0px 8px 0px" font="--headline3" color="--dark">
                Şampiyonlar Ekibimizle Tanışın
              </Text>
              <Text margin="0px 0px 0px 0px" font="--lead" color="--darkL2">
                BIGGYM Fitness'taki her başarılı antrenmanın arkasında
                sertifikalı fitness uzmanlarından oluşan ekibimiz vardır.
                Eğitmenlerimiz kendi alanlarında uzman olmaktan çok daha
                fazlasıdır - onlar sizin rehberiniz, motivasyon kaynağınız ve en
                büyük destekçinizdir. Kuvvet antrenmanı ve kardiyodan beslenme
                ve sağlıklı yaşama kadar her konuda farklı geçmişlere sahip olan
                ekibimiz, sizin kadar benzersiz bir fitness planı oluşturmanıza
                yardımcı olacak donanıma sahiptir. Size meydan okumak, sizi
                neşelendirmek ve büyük ya da küçük her zaferinizi kutlamak için
                buradalar.
              </Text>
            </Box>
          </Box>
          <Box
            width="50%"
            padding="0px 16px 0px 0px"
            align-items="flex-start"
            display="flex"
            justify-content="flex-start"
            margin="0px 0px 0px 0px"
            md-width="100%"
            lg-width="100%"
            lg-padding="0px 0px 0px 0px"
          >
            <Image
              src="https://uvixens.com/img/3.jpg"
              border-radius="24px"
              max-width="100%"
              max-height="356px"
              width="100%"
              object-fit="none"
            />
          </Box>
        </LinkBox>
        <LinkBox
          display="flex"
          margin="0px 0px 32px 0px"
          md-flex-direction="column"
          md-align-items="center"
          md-justify-content="center"
          flex-direction="row"
          align-items="flex-start"
          flex-wrap="wrap"
          lg-margin="0px 0px 16px 0px"
        >
          <Box
            width="50%"
            padding="0px 16px 0px 0px"
            align-items="flex-start"
            display="flex"
            justify-content="flex-start"
            margin="0px 0px 0px 0px"
            md-width="100%"
            lg-width="100%"
            lg-padding="0px 0px 0px 0px"
          >
            <Image
              src="https://uvixens.com/img/4.jpg"
              border-radius="24px"
              max-width="100%"
              max-height="356px"
              width="100%"
              object-fit="none"
            />
          </Box>
          <Box
            width="50%"
            padding="0px 0px 0px 16px"
            display="flex"
            align-items="flex-start"
            justify-content="flex-start"
            md-width="100%"
            sm-align-items="flex-start"
            sm-flex-direction="column"
            sm-justify-content="flex-start"
            flex-direction="column"
            lg-width="100%"
            lg-flex-direction="row"
            lg-justify-content="space-between"
            lg-padding="16px 12px 16px 12px"
          >
            <Box
              padding="0px 50px 0px 0px"
              lg-width="70%"
              lg-padding="0px 0px 0px 0px"
              sm-width="100%"
            >
              <Text margin="20px 0px 8px 0px" font="--headline3" color="--dark">
                Fitness Mirasımızın Bir Parçası Olun
              </Text>
              <Text margin="0px 0px 0px 0px" font="--lead" color="--darkL2">
                Fitness yolculuğunuz son derece kişiseldir ve BIGGYM Fitness'ta,
                yolun her adımında sizi desteklemek için buradayız.
                Topluluğumuza katılarak sadece egzersiz yapmadığınıza, ortak
                fitness mirasımızın dokusuna kendi hikayenizi de eklediğinize
                inanıyoruz. Dönüşümünüzün, zaferlerinizin ve daha sağlıklı, daha
                mutlu bir size giden yolculuğunuzun bir parçası olmamıza izin
                verin.
              </Text>
            </Box>
          </Box>
        </LinkBox>
      </Section>
      <Section padding="80px 0 80px 0" sm-padding="60px 0 60px 0">
        <Box
          width="100%"
          display="flex"
          flex-direction="column"
          md-width="100%"
          md-align-items="center"
          md-justify-content="center"
          md-text-align="center"
          lg-width="100%"
          margin="0px 0px 56px 0px"
          align-items="center"
        >
          <Text
            margin="0px 0px 16px 0px"
            font="--headline2"
            color="--dark"
            md-text-align="center"
            sm-font='normal 700 32px/1.2 "Source Sans Pro", sans-serif'
            text-align="center"
          >
            BIGGYM Neden Öne Çıkıyor?
          </Text>
        </Box>
        <Box
          display="grid"
          grid-template-columns="repeat(3, 1fr)"
          grid-gap="32px 4%"
          md-grid-template-columns="1fr"
        >
          <Box
            padding="45px 45px"
            lg-padding="45px 30px"
            md-padding="45px 45px"
            background="#FFFFFF"
            border-radius="24px"
            justify-content="flex-start"
            flex-direction="column"
            display="flex"
          >
            <Text
              margin="0px 0px 18px 0px"
              color="--darkL2"
              font="--headline3"
              lg-text-align="left"
            >
              Yenilikçi Programlar
            </Text>
            <Text
              margin="0px 0px 0px 0px"
              color="--greyD3"
              font="--base"
              lg-text-align="left"
              flex="1 0 auto"
            >
              Yüksek yoğunluklu aralıklı antrenmanlardan sakinleştirici yoga
              seanslarına kadar, programlarımız fitness yolculuğunuzda sizi
              meşgul ve heyecanlı tutmak için tasarlanmıştır.
            </Text>
          </Box>
          <Box
            padding="45px 45px"
            lg-padding="45px 30px"
            md-padding="45px 45px"
            background="#FFFFFF"
            border-radius="24px"
            justify-content="flex-start"
            flex-direction="column"
            display="flex"
          >
            <Text
              margin="0px 0px 18px 0px"
              color="--darkL2"
              font="--headline3"
              lg-text-align="left"
            >
              Topluluk Etkinlikleri
            </Text>
            <Text
              margin="0px 0px 0px 0px"
              color="--greyD3"
              font="--base"
              lg-text-align="left"
              flex="1 0 auto"
            >
              Fitness'ın eğlenceli olması gerektiğine inanıyoruz! Üyelerimizi
              sağlık ve zindelik ruhuyla bir araya getiren düzenli topluluk
              etkinlikleri ve atölye çalışmaları için bize katılın.
            </Text>
          </Box>
          <Box
            padding="45px 45px"
            lg-padding="45px 30px"
            md-padding="45px 45px"
            background="#FFFFFF"
            border-radius="24px"
            justify-content="flex-start"
            flex-direction="column"
            display="flex"
          >
            <Text
              margin="0px 0px 18px 0px"
              color="--darkL2"
              font="--headline3"
              lg-text-align="left"
            >
              Son Teknoloji Tesisler
            </Text>
            <Text
              margin="0px 0px 0px 0px"
              color="--greyD3"
              font="--base"
              lg-text-align="left"
              flex="1 0 auto"
            >
              Spor salonumuz, hedeflerinize ulaşmak için parmaklarınızın ucunda
              en iyi araçlara sahip olmanızı sağlayan en son fitness
              teknolojisiyle donatılmıştır.
            </Text>
          </Box>
        </Box>
      </Section>
      <Components.Contacts />
      <Components.Footer />
      <RawHtml>
        <style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
          {
            ":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"
          }
        </style>
      </RawHtml>
    </Theme>
  )
}
